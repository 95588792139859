import React from "react";

import { Routes, Route } from "react-router-dom";
import SigninPage from "./views/SigninPage";
import VerificationCodePage from "./views/VerificationCodePage";
import GenerateCodePage from "./views/GenerateCodePage";
import GenerateCodePageOptions from "./views/GenerateCodePageOptions";
import TAPPage from "./views/TAPPage";
import StrongAuthOptionsPage from "./views/StrongAuthOptionsPage";
import RegisterMicrosoftAuth from "./microsoft_auth_view/RegisterMicrosoftAuth";
import RegisterVerifyCode from "./microsoft_auth_view/RegisterVerifyCode";
import SuccessMessage from "./microsoft_auth_view/SuccessMessage";
import StrongOptionsWithAuth from "./views/StrongOptionsWithAuth";

function App(props) {
  const basePath = process.env.PUBLIC_URL;

  return (
    <React.Fragment>
      <header className="sysbar">
        <div className="items-container">
          <div className="item">
            <i className="icon icon-econ"></i>
            <span className="product">Strong Authentication Portal</span>
          </div>
        </div>
      </header>

      <div className="content tile ">
        <Routes>
          <Route
            exact
            path={basePath + "/"}
            element={<SigninPage></SigninPage>}
          />
          <Route
            exact
            path={basePath + "/index.html"}
            element={<SigninPage></SigninPage>}
          />
          <Route
            path={basePath + "/generateCode"}
            element={<GenerateCodePage></GenerateCodePage>}
          />
          <Route
            path={basePath + "/generateCodeOptions"}
            element={<GenerateCodePageOptions></GenerateCodePageOptions>}
          />
          <Route
            path={basePath + "/verifyCode"}
            element={<VerificationCodePage />}
          />
          <Route path={basePath + "/tap"} element={<TAPPage />} />
          <Route
            path={basePath + "/strongAuthOptions"}
            element={<StrongAuthOptionsPage />}
          />
          <Route
            exact
            path={basePath + "/strongOptionsWithAuth"}
            element={<StrongOptionsWithAuth />}
            />
          <Route
            path={basePath + "/registermicrosoftauth"}
            element={<RegisterMicrosoftAuth />}
          />
          <Route
            path={basePath + "/registerverifycode"}
            element={<RegisterVerifyCode />}
          />
          <Route
            path={basePath + "/registersuccessfully"}
            element={<SuccessMessage />}
          />
        </Routes>
      </div>
    </React.Fragment>
  );
}

export default App;
