import { React, useState, Fragment } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";

function GenerateCodePageOptions() {
  const basePath = process.env.PUBLIC_URL;
  const baseEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();
  const location = useLocation();
  const [apiError, setApiError] = useState({
    errorMessage: "",
    error: "",
  });
  const [emailError, setEmailError] = useState({
    errorMessage: "",
    error: "true",
  });
  const csrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  const [show, setShow] = useState(false);
  const [approverLM, setApproverLM] = useState(true);
  const [validEmail, setValidEmail] = useState(false);
  // const [lineManagerEmail, setLineManagerEmail] = useState(
  //   location.state.LmEmail,
  // )
  const handlePaste = (event) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let pasteValue = event.clipboardData.getData("text");
    if (regex.test(pasteValue)) {
      setValidEmail(true);
      setPrData({
        emailId: pasteValue,
      });
      setEmailError({
        ...emailError,
        errorMessage: "Valid email Id",
        error: false,
      });
    } else {
      setValidEmail(false);
      setEmailError({
        ...emailError,
        errorMessage: "Please type your email",
        error: true,
      });
    }
  };

  const [prEmail, setPrData] = useState({
    emailId: "",
  });
  const generateVerificationCode = () => {
    setApiError({
      ...apiError,
      error: true,
    });
    /*let axiosConfig = {
      headers: {
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
      },
    }*/
    axios.defaults.withCredentials = true;
    axios
      .post(
        baseEndPoint + (show ? "v2/generateNonce" : "generateNonce"),
        { email: prEmail.emailId, nonceType: "TAP" },
        {
          withCredentials: true,
          headers: {
            "X-CSRF-TOKEN": csrfToken,
          },
        }
      )
      .then((res) => {
        setApiError({
          ...apiError,
          error: false,
        });

        navigate(basePath + "/verifyCode", {
          state: {
            prEmail: prEmail.emailId,
          },
        });
      })
      .catch((err) => {
        if (err.code == "ERR_BAD_REQUEST") {
          setApiError({
            ...apiError,
            errorMessage: "Verification code generation failed.",
          });
        }
        if (err.code == "ERR_BAD_RESPONSE") {
          setApiError({
            ...apiError,
            errorMessage: err.response.data.message,
          });
        }
        if (err.code == "ERR_NETWORK") {
          setApiError({
            ...apiError,
            errorMessage: "Network Error",
          });
        }
      });
  };

  const setSpinner = () => {
    if (apiError.error) {
      return <div className="padding-span margin-left20 loading small"></div>;
    } else {
      return (
        <span className="padding-span">
          <span className={apiError.errorMessage ? "red-icon" : ""}>
            {apiError.errorMessage}
          </span>
        </span>
      );
    }
  };

  const setRadioOption = () => {
    return (
      <div className="tile">
        <input
          id="rbtn1"
          name="group1"
          type="radio"
          checked={approverLM}
          onChange={onChangeRadioValue}
        />
        <label htmlFor="rbtn1">
          Send email with verification code to your line manager
          {" " + location?.state?.LMEmail}
        </label>
        <br />
        <input
          id="rbtn2"
          name="group1"
          type="radio"
          checked={!approverLM}
          onChange={onChangeRadioValue}
        />
        <label htmlFor="rbtn2">
          Send email with verification code to an Ericsson employee sharing the
          same line manager as you{" "}
        </label>
        <br />
        {show ? (
          <Fragment>
            <input
              className={
                emailError.error ? "validation invalid" : "validation valid"
              }
              id={
                emailError.error ? "input-validation-error" : "input-validation"
              }
              onPaste={handlePaste}
              type="text"
              name=""
              tabIndex="0"
              autoFocus
              autoComplete="off"
              placeholder="firstname.lastname@ericsson.com"
              onFocus={(e) => {
                emailValidation();
                setPrData({
                  emailId: e.target.value,
                });
              }}
              onChange={(e) => {
                emailValidation();
                setPrData({
                  emailId: e.target.value,
                });
              }}
            />
            <span className="validation-msg">{emailError.errorMessage}</span>
          </Fragment>
        ) : null}
      </div>
    );
  };

  const onChangeRadioValue = (e) => {
    setApiError({
      ...apiError,
      errorMessage: "",
    });
    if (e.currentTarget.id == "rbtn2") {
      setShow(true);
      setApproverLM(false);
    } else {
      setShow(false);
      setApproverLM(true);
      setPrData({
        emailId: "",
      });
      setEmailError({
        ...emailError,
        errorMessage: "Please type your email",
        error: true,
      });
    }
  };

  const enableSubmit = () => {
    if (validEmail) {
      return false;
    }
    if (show) {
      return true;
    } else {
      return false;
    }
  };
  const emailValidation = () => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (regex.test(prEmail.emailId)) {
      setValidEmail(true);
      setEmailError({
        ...emailError,
        errorMessage: "Valid email Id",
        error: false,
      });
    } else {
      setEmailError({
        ...emailError,
        errorMessage: "Please type your email",
        error: true,
      });
      setValidEmail(false);
    }
  };

  return (
    <div className="row captcha_container">
      <div className="tile xl-4 lg-3 sm-12"></div>
      <div className="xl-4 lg-6 sm-12">
        <div className="card">
          <div className="header sm-12">
            <div className="left">
              <div className="title">Step 3 - Verification Code</div>
            </div>
          </div>
          <br />
          <div className="tile">
            <p>
              To verify your identity a verification code will be sent to your{" "}
              <b>
                line manager or to an Ericsson employee sharing the same line
                manager as you. Select your option
              </b>
              .
            </p>
            {setRadioOption()}
            <br />
            <p>
              This verification code is valid for 24 hours and is required to
              generate the <b>Temporary Access Pass(TAP)</b>.
            </p>
            <p>
              <b>Caution: </b>Before clicking on generate verification code
              button please ensure that you can receive this code from your your
              line manager or from an Ericsson employee sharing the same line
              manager as you.
            </p>
          </div>

          <div className="row tile margin-top20">
            <button
              className="btn primary"
              disabled={enableSubmit()}
              onClick={(e) => generateVerificationCode()}
            >
              Generate Verification Code
            </button>
            {setSpinner()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateCodePageOptions;
