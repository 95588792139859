import { React, useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
function TAPPage() {
  const baseEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const location = useLocation();
  const [TAP, setTAP] = useState("Loading...");
  const sendTAP = () => {};
  const csrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  const getTap = () => {
    /*let axiosConfig = {
     /* headers: {
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
      },
      postLoginData: {
        //verificationCode: verificationCodeRef.current.value,
      },
    }*/
    // axios.defaults.withCredentials = true
    axios
      .post(
        baseEndPoint + "createTap",
        { nonce: location.state.verificationCode },
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      )
      .then((res) => {
        setTAP(res.data);
      })
      .catch((err) => {
        if (err.code == "ERR_BAD_REQUEST") {
          setTAP("TAP generation failed");
        }
        if (err.code == "ERR_NETWORK") {
          setTAP("Network Error");
        }
      });
  };
  useEffect(() => {
    getTap();
  }, []);
  return (
    <div className="row captcha_container">
      <div className="tile xl-4 lg-3 sm-12"></div>
      <div className="xl-4 lg-6 sm-12">
        <div className="card">
          <div className="header sm-12">
            <div className="left">
              <div className="title">Step 5 - Temporary Access Pass </div>
            </div>
          </div>

          <div className="tile">
            <br />
            <p>
              Please make a note of your Temporary Access Pass as it would not
              be retrived by anyone not even by system adminstrator, if lost.
              <br />
              <br />
              <span className="red_text">
                TAP is valid only for next 60 mins, so please make sure you
                consume it before it expires.
              </span>
            </p>
          </div>

          <div className="tile">
            <label htmlFor="TAP">
              Temporary Access Pass: &nbsp;&nbsp;
              <span className="blue_text">{TAP}</span>
            </label>

            {/* <label for="TAP">{TAP}</label> */}
          </div>
          <div className="tile">
            <p>
              <a
                href="https://aka.ms/mfasetup/"
                target="_blank"
                style={{ textDecoration: "underline", fontWeight: "600" }}
              >
                Click here
              </a>{" "}
              to register authentication methods using TAP.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TAPPage;
